import { usersStore } from '~/stores/users';

export enum FeatureName {
  quoteTabs = 'quoteTabs',
  insights = 'insights',
};
type FeatureFlags = Record<FeatureName, () => boolean>;

const clientFeaturesEnabled: Record<FeatureName, number[]> = {
  [FeatureName.quoteTabs]: [],
  [FeatureName.insights]: [],
};

const isStagingOrDev = import.meta.env.MODE !== 'production';

export const useFeaturesStore = defineStore('features', () => {
  const features = ref<FeatureFlags>({} as FeatureFlags);
  const isEnabled = (feature: FeatureName) => !!features.value[feature]();

  const userStore = usersStore();
  const { currentUser } = storeToRefs(userStore);
  function isClientEnabled(featureName: FeatureName) {
    return import.meta.env.MODE === 'production' && clientFeaturesEnabled[featureName].includes(currentUser.value?.client_id || 0);
  };

  function initFlags() {
    features.value = {
      [FeatureName.quoteTabs]: () => isStagingOrDev || isClientEnabled(FeatureName.quoteTabs),
      [FeatureName.insights]: () => isStagingOrDev || isClientEnabled(FeatureName.insights),
    };
  }

  return {
    isEnabled,
    initFlags,
  };
});
